<template>
	<PaymentSummaryCard :paymentSummary="filteredPaymentSummary" />
</template>

<script setup lang="ts">
import { formatPrice } from "~/helpers/formatting/price";
import { useStore } from "vuex";
import {
	productIsEligibleForDownPayment,
	getValidatedSubscriptionPrice,
} from "~/helpers/ecommerce/hardwareOrderHelper";

const props = defineProps({
	showSubItems: {
		type: Boolean,
		default: true,
	},
	terminals: {
		type: Array,
		default: () => [],
	},
});

const store = useStore();

const shoppingCart = computed(() =>
	props.terminals.length > 0 ? props.terminals : store.getters["shoppingCart/visibleCartItems"],
);

const { phoneData, estimate, tradeInBonus } = useTradeIn(shoppingCart.value);
const { prices, fullPricePrices } = useGetPrices(shoppingCart.value);
const baseProduct = computed(() => shoppingCart.value.find((item: any) => item.type === "hardware"));
const visibleSubscriptionsInCart = computed(() =>
	shoppingCart.value?.[0]?.subItems?.filter(
		(item: { type: string }) => item.type === "subscription" || item.type === "msisdn",
	),
);
const isVatExempt = computed(() => baseProduct.value?.metadata?.isVatExempt);
const subscriptions = computed(() => store.getters["shoppingCart/subscriptions"]);

const isExistingSubscription = (item: { type: string; metadata: { existingSubscription: any } }) =>
	item?.type === "subscription" && item?.metadata?.existingSubscription;

const validatedSubscriptionPrices = computed(() =>
	getValidatedSubscriptionPrice(subscriptions.value, visibleSubscriptionsInCart.value),
);

const insurance = computed(() => baseProduct.value?.subItems?.find((item: any) => item.type === "agreement"));
const subscription = computed(() => baseProduct.value?.subItems?.find((item: any) => item.type === "subscription"));

const totalMonthly = computed(() => {
	const getCorrectMonthlyPrice = (item: { metadata: any; type: any }) => {
		if (productIsEligibleForDownPayment(item)) {
			return item?.metadata?.downPayment ? item?.metadata?.downPaymentPlan?.prices?.monthlyListPrice.value : 0;
		} else {
			if (isExistingSubscription(item)) {
				return 0;
			} else if (item.type === "subscription") {
				if (validatedSubscriptionPrices.value?.originalPrice > 0) {
					return validatedSubscriptionPrices.value?.discountedPrice || validatedSubscriptionPrices.value?.originalPrice;
				} else {
					return item.metadata?.showFallback || item.metadata?.recurringPrice || 0;
				}
			} else {
				return item.metadata?.saleRecurringPrice || item.metadata?.recurringPrice || 0;
			}
		}
	};
	return shoppingCart.value
		.filter((item: { type: string }) => item?.type !== "old-swap-device")
		.map((item: { subItems: any }) => (props.showSubItems ? [item, ...(item?.subItems ?? [])] : [item]))
		.flat()
		.map(getCorrectMonthlyPrice)
		.reduce((a: any, b: any) => a + b, 0);
});

const totalOneTime = computed(() => {
	const getCorrectOneTimePrice = (item: {
		metadata: {
			downPayment: any;
			prices: { withoutVat: any; withVat: any };
			downPaymentPlan: { amountUpFront: number };
			price: any;
		};
		subItems: any[];
	}) => {
		const isDownPayment = item.metadata?.downPayment;
		const prices = isVatExempt.value ? item.metadata?.prices?.withoutVat : item.metadata?.prices?.withVat;
		const normalPrice = prices?.fullPrice?.price;
		const salePrice = prices?.fullPrice?.salePrice;
		const hasSubscriptionInCart: boolean = item.subItems?.some((subItem): boolean => subItem.type === "subscription");
		const memberPrice = hasSubscriptionInCart ? prices?.fullPrice?.memberPrice : undefined;
		const amountUpFront = item?.metadata?.downPaymentPlan?.amountUpFront || 0;

		if (productIsEligibleForDownPayment(item) && isDownPayment) return amountUpFront;
		return memberPrice || salePrice || normalPrice || item.metadata?.price || 0;
	};

	return shoppingCart.value
		.filter((item: { type: string }) => item?.type !== "old-swap-device")
		.map((item: { subItems: any }) => (props.showSubItems ? [item, ...(item?.subItems ?? {})] : [item]))
		.flat()
		.map(getCorrectOneTimePrice)
		.reduce((a: any, b: any) => a + b, 0);
});

const memberPrice = computed(() => {
	return typeof subscription.value !== "undefined" ? prices.value.fullPrice?.memberPrice : undefined;
});

const amountUpFront = computed(() => {
	return baseProduct.value?.metadata?.downPaymentPlan?.amountUpFront || 0;
});

const campaignDiscount = computed(() => {
	// Prioritizes memberPrice if subscription is present and has both salePrice and memberPrice
	const deductedPrice = memberPrice.value || prices.value?.fullPrice?.salePrice;
	return deductedPrice ? prices.value?.fullPrice?.price - deductedPrice : 0;
});

const paymentSummary = computed(() => {
	const summary = {
		totalMonthly: totalMonthly.value,
		totalOneTime: totalOneTime.value,
		paymentLines: [
			{
				id: "CAMPAIGN",
				name: "Kampanje",
				description: undefined,
				price: `- ${formatPrice.oneTime(campaignDiscount.value)}`,
				show: campaignDiscount?.value,
			},
			{
				id: "TRADE-IN",
				name: "Innbytte",
				description: phoneData.value?.name || undefined,
				price: `- ${formatPrice.oneTime(estimate.value)}`,
				show: estimate?.value && baseProduct.value?.metadata?.downPayment,
			},
			{
				id: "TRADE-IN-BONUS",
				name: "Innbytte-bonus",
				description: undefined,
				price: `- ${formatPrice.oneTime(tradeInBonus.value)}`,
				show: tradeInBonus.value && estimate?.value && baseProduct.value?.metadata?.downPayment,
			},
			{
				id: "DOWN-PAYMENT",
				name: baseProduct.value?.metadata?.name,
				description: amountUpFront.value ? "Valgfritt kontantbeløp" : undefined,
				price: formatPrice.monthly(baseProduct.value?.metadata?.downPaymentPlan?.prices?.monthlyListPrice?.value),
				subPrice: amountUpFront.value ? formatPrice.oneTime(amountUpFront.value) : undefined,
				show: baseProduct.value?.metadata?.downPayment,
			},
			{
				id: "FULL-PRICE",
				name: baseProduct.value?.metadata?.name,
				description: undefined,
				price: formatPrice.oneTime(fullPricePrices.value?.price),
				show: !baseProduct.value?.metadata?.downPayment,
			},
			{
				id: "INSURANCE",
				name: insurance.value?.metadata?.name,
				description: undefined,
				price: formatPrice.monthly(insurance.value?.metadata?.recurringPrice),
				show: insurance.value,
			},
			{
				id: "SUBSCRIPTION",
				name: subscription.value?.metadata?.name,
				description: undefined,
				price: formatPrice.monthly(subscription.value?.metadata?.recurringPrice),
				show: subscription.value && !isExistingSubscription(subscription.value),
			},
		],
	};

	summary.paymentLines = [...summary.paymentLines, ...getHardwareSubItems(baseProduct.value)];

	return summary;
});

const getHardwareSubItems = (item: { subItems: any[] }) => {
	const hardwareSubItems = item?.subItems?.filter((subItem: any) => subItem.type === "hardware") || [];

	return (
		hardwareSubItems?.map((subItem) => ({
			id: subItem.id,
			name: subItem.metadata?.name,
			description: undefined,
			price: formatPrice.oneTime(
				subItem.metadata?.prices?.withVat?.fullPrice?.salePrice || subItem.metadata?.prices?.withVat?.fullPrice?.price,
			),
			show: true,
		})) ?? []
	);
};

const filteredPaymentSummary = computed(() => ({
	totalMonthly: paymentSummary.value.totalMonthly,
	totalOneTime: paymentSummary.value.totalOneTime,
	paymentLines: paymentSummary.value.paymentLines?.filter((line) => line?.show),
}));
</script>
