<template>
	<TnCard
		data-cy-payment-summary-card
		border
		class="payment-summary-container"
	>
		<PaymentSummaryTotal
			:monthlyAmount="paymentSummary.totalMonthly"
			:oneTimeAmount="paymentSummary.totalOneTime"
		/>
		<hr class="divider margin-top-s" />
		<div class="expandable margin-top-s">
			<div
				class="expandable-header"
				@click="expandableOpen = !expandableOpen"
			>
				<TnParagraph size="s"> Se alle priser og rabatter </TnParagraph>
				<TnIcon
					class="icon"
					:name="expandableOpen ? 'chevron-up' : 'chevron-down'"
				/>
			</div>
			<div
				class="expandable-body margin-top-s"
				:class="{ open: expandableOpen }"
			>
				<div class="payment-lines">
					<PaymentSummaryLine
						v-for="line in paymentSummary.paymentLines"
						:key="line.id"
						:hasBorder="true"
					>
						<template #line-left>
							<TnParagraph size="s">{{ line.name }}</TnParagraph>
							<TnParagraph
								v-if="line.description"
								size="xs"
								class="line-description"
							>
								{{ line.description }}
							</TnParagraph>
						</template>
						<template #line-right>
							<TnParagraph size="s">{{ line.price }}</TnParagraph>
							<TnParagraph
								v-if="line.subPrice"
								size="xs"
								class="line-description"
							>
								{{ line.subPrice }}
							</TnParagraph>
						</template>
					</PaymentSummaryLine>
				</div>
			</div>
		</div>
	</TnCard>
</template>

<script setup lang="ts">
const expandableOpen = ref(false);
const { paymentSummary } = defineProps<{
	paymentSummary: any;
}>();
</script>

<style scoped lang="scss">
.card.payment-summary-container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: $spacing-m !important;
}

.price-discount {
	text-decoration: line-through;
	color: $color-neutrals-400-tint;
}

.border {
	display: block;
	margin: auto;
	width: 100%;
	border-radius: 4px;
}

.icon {
	pointer-events: none;
}

.divider {
	width: 100%;
	border: solid 1px #e5e8f0;
	box-sizing: border-box;
}

.line-description {
	color: $color-neutrals-700-shade;
}

.expandable {
	margin-bottom: -12px;
	&-header {
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-body {
		display: grid;
		grid-template-rows: 0fr;
		overflow: hidden;
		transition: grid-template-rows 200ms;

		div {
			overflow: hidden;
		}

		.payment-lines {
			display: flex;
			flex-direction: column;
		}

		&.open {
			grid-template-rows: 1fr;
		}
	}
}
</style>
